<style scoped>
.rtsp {
  background-color: #666;
  cursor: pointer;
}
.canvas {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="rtsp" ref="root" :style="control.style" @click="onClick">
    <v-dialog v-if="isView" v-model="show" width="320" persistent>
      <v-card>
        <v-card-title>{{ $vuetify.lang.t("$vuetify.key.K_%E5%9B%9E%E6%94%BE%E9%80%89%E9%A1%B9") }}</v-card-title>
        <v-card-text>
          <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E9%80%9A%E9%81%93')" v-model="channel"></v-text-field>
          <v-menu
            v-model="dateShow"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$vuetify.lang.t('$vuetify.key.K_%E6%97%A5%E6%9C%9F')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="dateShow = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="timeShow"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :label="$vuetify.lang.t('$vuetify.key.K_%E6%97%B6%E9%97%B4')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="time"
              format="24hr"
              use-seconds
            ></v-time-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E5%9B%9E%E6%94%BE") }}</v-btn>
          <v-btn text @click="show = false">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-if="isView && player == null"
      class="d-flex fill-height align-center justify-center"
    >
      <h1 class="white--text">{{ $vuetify.lang.t("$vuetify.key.K_%E7%82%B9%E5%87%BB%E8%AE%BE%E7%BD%AE%E5%9B%9E%E6%94%BE%E5%8F%82%E6%95%B0") }}</h1>
    </div>
    <canvas v-show="isView && player" ref="canvas" class="canvas"></canvas>
  </div>
</template>

<script>
import client from "../utils/client";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      player: null,
      show: false,
      channel: "1",

      dateShow: false,
      date: new Date(Date.now() + 8 * 3600000).toISOString().substr(0, 10),

      timeShow: false,
      time: "08:00:00",
    };
  },
  beforeDestroy() {
    if (this.player) {
      try {
        this.player.destroy();
      } catch (e) {
        e.toString();
      }
      this.player = null;
    }
  },
  methods: {
    onClick() {
      if (this.isView) {
        this.show = true;
      }
    },
    submit() {
      this.show = false;
      if (this.player) {
        try {
          this.player.destroy();
        } catch (e) {
          e.toString();
        }
        this.player = null;
      }
      let rtsp;
      let host = this.control.config.host;
      let port = this.control.config.port;
      let account = this.control.config.account;
      let password = this.control.config.password;
      let manufacturer = this.control.config.manufacturer;
      let channel = this.channel;
      if (manufacturer == "1") {
        let starttime = `${this.date.replace(/-/g, "")}t${this.time.replace(
          /:/g,
          ""
        )}z`;
        rtsp = `rtsp://${account}:${password}@${host}:${port}/Streaming/tracks/${channel}01?=starttime=${starttime}`;
      }
      if (rtsp) {
        let isMini = navigator.userAgent.toLowerCase().indexOf("miniprogram") != -1;
        let protocol = window.location.protocol == "http:" ? "ws:" : "wss:";
        let host = client.loginInfo.host;
        let port = client.loginInfo.port;
        let url;
        if (isMini) {
          url = `${protocol}//wx-ivisual.szzht.com/rtsp/${encodeURIComponent(
            rtsp
          )}/${host}/${port}`;
        } else if (port == 80) {
          url = `ws://${host}/rtsp/${encodeURIComponent(rtsp)}`;
        } else if (port == 443) {
          url = `wss://${host}/rtsp/${encodeURIComponent(rtsp)}`;
        } else {
          url = `${protocol}//${host}:${port}/rtsp/${encodeURIComponent(rtsp)}`;
        }
        this.player = new window.JSMpeg.Player(url, {
          canvas: this.$refs.canvas,
          pauseWhenHidden: false,
          videoBufferSize: 1024 * 1024,
        });
      }
    },
  },
};
</script>
